import Vue from 'vue';
import VueRouter from 'vue-router';
import { page } from 'vue-analytics';
import { ALLOWED_ROLES } from 'config';

import { RelocityAuthCallback } from '@relocity/auth';

// next route
function getNextRoute() {
    let nextRoute = localStorage.getItem('next-route');
    localStorage.removeItem('next-route');
    return nextRoute ? JSON.parse(nextRoute) : { name: 'dashboard' };
}

let routes = [
    {
        path: '/',
        name: 'home',
        component: require('views/Home').default,
    },
    {
        path: '/records/:category/:id',
        props: true,
        name: 'record',
        component: require('views/Record').default,
    },
    {
        path: '/callback',
        name: 'sso-callback',
        component: RelocityAuthCallback,
        props: {
            redirectAfterAuth: getNextRoute(),
        },
    },
    {
        path: '/logout',
        name: 'sso-logout',
    },
    {
        path: '/login',
        name: 'sso-login',
    },
    {
        path: '/dashboard',
        name: 'dashboard',
    },
    {
        path: '/dashboard/users',
        name: 'users',
        component: () =>
            import(
                /* webpackChunkName: "users" */
                'views/dashboard/Users'
            ),
    },
    {
        path: '/dashboard/users/:user/maps',
        props: true,
        name: 'user-maps',
        component: () =>
            import(
                /* webpackChunkName: "maps" */
                'views/dashboard/Maps'
            ),
    },
    {
        path: '/dashboard/users/:user/maps/:map',
        props: true,
        name: 'edit-map',
        component: () =>
            import(
                /* webpackChunkName: "edit-maps" */
                'views/dashboard/EditMap'
            ),
    },
    {
        path: '/dashboard/work-orders/:workOrder',
        props: true,
        name: 'edit-work-order-map',
        component: () =>
            import(
                /* webpackChunkName: "edit-maps" */
                'views/dashboard/EditMap'
            ),
    },
    {
        path: '/:map',
        props: true,
        name: 'map',
        component: () =>
            import(
                /* webpackChunkName: "sharable-map" */
                'views/SharableMap'
            ),
    },
    {
        path: '/host/:map',
        props: true,
        name: 'host-map',
        component: () =>
            import(
                /* webpackChunkName: "host-map" */
                'views/Map'
            ),
    },
];

const router = new VueRouter({ mode: 'history', routes });

router.beforeEach((to, from, next) => {
    if (!canView(to.name)) {
        storeNextRoute();
        next({ name: 'home' });
        return;
    }

    switch (to.name) {
        case 'sso-callback':
            next();
            break;
        case 'sso-login':
            router.app.$auth.login();
            break;
        case 'sso-logout':
            router.app.$auth.logout();
            break;
        case 'map':
            next();
            break;
        case 'host-map':
            next();
            break;
        case 'home':
            next();
            break;
        case 'user-maps':
            next();
            break;
        case 'edit-map':
            next();
            break;
        case 'edit-work-order-map':
            next();
            break;
        case 'dashboard':
            gotoDashboard();
            break;
        case 'record':
            next();
            break;
        default:
            if (router.app.$auth.isAuthenticated()) {
                next();
            } else {
                // Save the current route before redirecting to login
                storeNextRoute();
                router.app.$auth.login();
            }
            break;
    }

    function storeNextRoute() {
        localStorage.setItem('next-route', JSON.stringify({ name: to.name, params: to.params }));
    }

    function canView(routeName) {
        let authUser = router.app.$auth.getAuthUser();

        const routeAllowed = ['home', 'sso-callback', 'sso-logout', 'sso-login', 'map', 'host-map', 'record'].includes(
            routeName
        );

        if (authUser && authUser.role.name != 'Client') {
            return ALLOWED_ROLES.includes(authUser.role.name) || routeAllowed;
        }

        return routeAllowed;
    }

    function gotoDashboard() {
        let authUser = router.app.$auth.getAuthUser();
        if (authUser && authUser.role.name == 'Host') {
            next({
                path: '/dashboard/users/' + authUser.id + '/maps',
            });
        } else {
            next({ path: '/dashboard/users' });
        }
    }
});

router.afterEach((to) => {
    page(to.path);
});

Vue.use(VueRouter);

export default router;
